import i18n from '../lib/i18n/i18n'
import React, { useEffect } from 'react'

import { Box, Container, Center, Text, Stack, Link, Heading, View } from 'native-base'

import { StyleSheet } from 'react-native'

import { StatusBar } from 'expo-status-bar'

export default function CopyCenters ({ route, navigation }) {
  useEffect(() => {
    document.getElementsByTagName('meta').namedItem('robots').setAttribute('content', 'index, follow')
  }, [])

  return (
    <Box flex={1} bg="#fff">
      <Stack flexDirection="column" space={4} alignItems="center" justifyContent="center">
        <Box id="hero" bg={{
          linearGradient: {
            colors: ['#0551d0', '#01b3f9'],
            start: [0, 0.5],
            end: [0, 0.5]
            // locations: [0.5, 0.5] // TODO: light blue from both sides
          }
        }} width="98vw" height="107vh" flexDirection="row">
          <Center marginLeft={['0vw', '0vw', '15vw']}>
            <Container>
              <Stack direction={['column', 'column', 'row']} space={4}>
                <Box id="leftPanel" maxWidth={['80vw', '80vw', '30vw']} justifyContent={['flex-start', 'flex-start', 'center']} marginTop={['12vh', '12vh', '0vh']} alignItems={['center', 'center', 'left']} textAlign={['center', 'center', 'left']}>
                  <Heading size="lg" fontSize={34} fontWeight="600" color="#fff" marginBottom="15px">
                    {i18n.t('copy_centers_landing_page.headline')}
                  </Heading>
                  <Heading size="sm" color="#fff" fontSize={16} fontWeight="400" marginTop="15px">
                    {i18n.t('copy_centers_landing_page.hero_paragraph_1')}
                  </Heading>
                  <Heading size="sm" color="#fff" fontSize={16} fontWeight="400" marginTop="15px">
                    {i18n.t('copy_centers_landing_page.hero_paragraph_2')}
                  </Heading>
                  <Box w="260" rounded="lg" marginTop="5">
                    <Box style={styles.button}>
                      <Center>
                        <Link style={styles.buttonText} isUnderlined={false} isExternal href="https://sakirtemel.typeform.com/to/t6ibxBZ9">{i18n.t('copy_centers_landing_page.click_to_action')}</Link>
                      </Center>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Center>
        </Box>
        <StatusBar style='auto' />
        <View nativeID="contact">
          <Box bgColor="#0551d0" width="98vw" py="10">
            <Center>
              <Text color="#fff">
                {i18n.t('footer.for_contact')} : info @ foldercode.com or <Link href="https://sakirtemel.typeform.com/to/t6ibxBZ9" isExternal isUnderlined>{i18n.t('footer.feedback_form')}</Link>
              </Text>
            </Center>
          </Box>
        </View>
      </Stack>
    </Box>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#23D3D3',
    color: '#040e1a',
    border: '1px solid #23D3D3',
    padding: 15,
    borderRadius: 5,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  buttonText: {
    // fontSize: 18,
    // color: '#fff',
    // textAlign: 'center'
  }
})
