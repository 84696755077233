import { Box, Text, View } from 'native-base'
import { StyleSheet } from 'react-native'

import { FileIcon, defaultStyles } from 'react-file-icon'

export default function LandingPageFileItem ({ item, percentage }) {
  const fileExtension = item.filename.split('.').pop()
  const fileRowBg = item.status === 'uploaded' ? '#8fbc8f' : (item.status === 'started' ? '#EEE8AA' : (item.status === 'error' ? '#FF9494' : '#FBFBFB'))
  const progressBarBg = item.status !== 'started'
    ? {}
    : {
        linearGradient: {
          colors: [('rgba(143,188,143,' + (0.8 + ((0.2 * percentage) / 100)).toString() + ')'), ('rgba(238,232,170,' + (0.3 + (0.7 * (1 - (percentage / 100)))).toString() + ')')],
          start: [0, 0.5],
          end: [0, 0.5],
          locations: [(percentage === 0 ? 0.01 : percentage) / 100, (percentage === 0 ? 0.01 : percentage) / 100]
        }
      }

  return (
        <Box p="4px" my="0.5px" mx="10px" justifyContent="left" alignItems="left" textAlignVertical="center" style={{ backgroundColor: fileRowBg, flex: 1, flexDirection: 'row' }} bg={progressBarBg}>
        <View style={styles.fileRowIcon}>
            <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]}/>
        </View>
        <View style={{ flex: 1, marginLeft: 10 }}>
            <View style={styles.fileRowFilename}>
            <Text>{item.filename}</Text>
            {item.status === 'failed' && (
                <Text>{item.error}</Text>
            )}
            </View>
        </View>
    </Box>
  )
}

const styles = StyleSheet.create({
  fileRowIcon: {
    width: '16px',
    height: '16px'
    // flex: 0.2,
  },
  fileRowFilename: {
    // paddingLeft: '3%',
    justifyContent: 'center',
    flex: 1
  },
  fileRowButton: {
    // flex: 0.3,
  },
  fileRowOpenInNewTabButton: {
    color: 'blue'
  }
})
