import React, { useEffect } from 'react'
import {
  Box,
  Center,
  Text,
  Heading,
  Container,
  Stack,
  Spinner,
  Tooltip,
  CheckIcon,
  HStack,
  View,
  Link,
  Spacer, Icon, Image, VStack
} from 'native-base'
import QRCode from 'react-qr-code'

import { StatusBar } from 'expo-status-bar'
import { StyleSheet, TouchableOpacity, Linking, ScrollView, FlatList } from 'react-native'
import { Platform } from 'expo-modules-core'

import { useFlags } from 'flagsmith/react'

import * as DocumentPicker from 'expo-document-picker'

import LandingPageFileItem from './LandingPageFileItem.js'
// import LandingPageNumbers from './LandingPageNumbers.js'
import LandingPageFooter from './LandingPageFooter.js'
import LandingPageAbout from './LandingPageAbout.js'
import LandingPageTechnology from './LandingPageTechnology.js'
import LandingPageFaq from './LandingPageFaq.js'

import i18n from '../lib/i18n/i18n'
import SequentialFileUploader from '../lib/api/sequentialFileUploader'
import {Feather, FontAwesome5} from "@expo/vector-icons";

// TODO: how this state is connected? we need to listen to automated changes here
function FilesList ({ filesList, progress }) {
  if (filesList === null) {
    return null
  }

  const percentage = progress || 0

  return (
    <ScrollView showsVerticalScrollIndicator={true}>
      <FlatList
      data={filesList}
      keyExtractor={({ uuid }, index) => uuid} // eslint-disable-line camelcase
      renderItem={({ item }) => <LandingPageFileItem key={item.uuid} item={item} percentage={percentage}/>}
      />
    </ScrollView>
  )
}

export default function LandingPage () {
  const flags = useFlags(['multiple_files', 'api_base_url', 'api_token'])
  const [selectedImage, setSelectedImage] = React.useState(null)
  const [sequentialFileUploader, setSequentialFileUploader] = React.useState(null)
  const [uploadingNow, setUploadingNow] = React.useState(null)
  const [progress, setProgress] = React.useState(null)
  const [draggingOverNow, setDraggingOverNow] = React.useState(null)
  const [fileList, setFileList] = React.useState([])
  const [folderCode, setFolderCode] = React.useState('')

  const handleUpdate = (newState) => {
    setUploadingNow(newState.uploadingNow)
    setProgress(newState.progress)
    setFolderCode(newState.folderCode)
    window.history.pushState(null, '', '/' + newState.folderCode)
    setFileList([...newState.filesList])
  }

  useEffect(() => {
    if (!flags.api_base_url.value) {
      return
    }

    document.getElementsByTagName('meta').namedItem('robots') && document.getElementsByTagName('meta').namedItem('robots').setAttribute('content', 'index, follow')

    if (Platform.isDOMAvailable) {
      window.ondragover = handleDragOver
      window.ondragleave = handleDragOver
      window.ondrop = handleDrop
    }

    window.sequentialFileUploader = new SequentialFileUploader({ apiBaseUrl: flags.api_base_url.value, apiToken: flags.api_token.value, onUpdate: handleUpdate })
    setSequentialFileUploader(window.sequentialFileUploader) // TODO: singleton
  }, [flags])

  const openDocumentPickerAsync = async () => {
    const pickerResult = await DocumentPicker.getDocumentAsync({
      multiple: flags.multiple_files.enabled,
      type: '*/*'
    })
    if (pickerResult.cancelled === true) {
      return
    }
    sequentialFileUploader.enqueueFilesForUpload(pickerResult.output)

    setSelectedImage({ localUri: pickerResult.uri })
  }

  const traverseFileTree = (item, path) => {
    path = path || ''
    if (item.isFile) {
      // Get file
      item.file(function (file) {
        // console.log(path) => in the future, it can be used as file description
        window.sequentialFileUploader.enqueueFilesForUpload([file])
      })
    } else if (item.isDirectory) {
      // Get folder contents
      const dirReader = item.createReader()
      dirReader.readEntries(function (entries) {
        for (let i = 0; i < entries.length; i++) {
          traverseFileTree(entries[i], path + item.name + '/')
        }
      })
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    if (event.type === 'dragover') {
      setDraggingOverNow(true)
    } else {
      setDraggingOverNow(false)
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setDraggingOverNow(false)
    const items = event.dataTransfer.items
    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry()
      if (item) {
        traverseFileTree(item)
      }
    }
  }

  if (selectedImage !== null) {
    // console.log(selectedImage.localUri);
  }

  return (
    <Box flex={1} bg="#fff" onDrop={handleDrop} onDragOver={(event) => event.preventDefault()}>
      <Stack flexDirection="column" alignItems="center" justifyContent="center">
        <Box id="hero" bg={{
          linearGradient: {
            colors: ['#0551d0', '#01b3f9'],
            start: [0, 0.5],
            end: [0, 0.5]
            // locations: [0.5, 0.5] // TODO: light blue from both sides
          }
        }} width="100%" height="91vh" flexDirection="row">
          <Center marginLeft={['0vw', '0vw', '15vw']}>
            <Container>
              <Stack direction={['column', 'column', 'row']} space={4}>
                <Box id="leftPanel" maxWidth={['80vw', '80vw', '30vw']} justifyContent={['flex-start', 'flex-start', 'center']} marginTop={['12vh', '12vh', '0vh']} alignItems={['center', 'center', 'left']} textAlign={['center', 'center', 'left']}>
                  <Center>
                    <VStack>
                      <Center>
                        <Image
                            alt='FolderCode logo'
                            source={require('../assets/icon.png')}
                            width={75}
                            height={75}
                        />
                        {folderCode && (
                          <Tooltip label={i18n.t('landing_page_right_panel.header_tooltip')}>
                            <Text fontSize={24} mt={4} mb={4}>{i18n.t('landing_page_right_panel.your_code')}:</Text>
                          </Tooltip>
                        )}
                      </Center>
                      <Text style={{ fontSize: 36, fontWeight: '600', letterSpacing: 0, color: '#fff' }} alignItems="center" justifyContent="center">
                        {folderCode && (
                            <Text style={styles.folderLinkProtocol} fontSize={36} mr={1} color={'blueGray.400'}>www.</Text>
                        )}
                        {i18n.t('host')}
                        {folderCode && (
                          <Text style={styles.folderLink} fontSize="36" onPress={ () => { Linking.openURL('/' + folderCode) }}><Text style={styles.folderHyperLink}>/<Text style={styles.folderCode} color={'#F5B658'} fontWeight="extrabold" isUnderlined>{folderCode || (<Spinner justifyContent="center" color="cyan.200" size="sm"/>)}</Text></Text></Text>
                        )}
                      </Text>
                    </VStack>
                  </Center>
                  {!folderCode &&
                    <View>
                      <Heading size="lg" fontSize={28} fontWeight="600" color="#fff" marginBottom="6px" mt={2}>
                        {i18n.t('landing_page.headline')}
                      </Heading>
                      <HStack space={2} mt="8">
                        <HStack space={2}>
                          <CheckIcon size="4" mt="0.5" color="emerald.500" />
                          <Text color="coolGray.100" fontSize="xs" textTransform="uppercase">
                            {i18n.t('landing_page.hero_badge_1')}
                          </Text>
                        </HStack>
                        <HStack space={2}>
                          <CheckIcon size="4" mt="0.5" color="emerald.500" />
                          <Text color="coolGray.100" fontSize="xs" textTransform="uppercase">
                            {i18n.t('landing_page.hero_badge_2')}
                          </Text>
                        </HStack>
                        <HStack space={2}>
                          <CheckIcon size="4" mt="0.5" color="emerald.500" />
                          <Text color="coolGray.100" fontSize="xs" textTransform="uppercase">
                            {i18n.t('landing_page.hero_badge_3')}
                          </Text>
                        </HStack>
                      </HStack>
                      <Box alignItems="left" width="48" mt="2">
                        <Text color="coolGray.300" fontSize="xs">{i18n.t('landing_page.hero_badge_4')}</Text>
                      </Box>
                      <Box alignItems="left" justifyContent="left" mt="2" textAlign="left" width="320px" marginLeft="0" paddingLeft="0">
                        <div style={{ justifyContent: 'left' }} className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="631112783b8e212b89ba33be" data-style-justify-content="left" data-style-margin="0" data-style-width="100%" data-style-height="24px" data-theme="dark" data-min-review-count="10" data-without-reviews-preferred-string-id="3" data-style-alignment="left">
                          <a href="https://www.trustpilot.com/review/foldercode.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                        </div>
                      </Box>
                    </View>
                  }
                  {folderCode &&
                    <View>
                      <Heading size="lg" fontSize={28} fontWeight="600" color="#fff" marginBottom="6px" mt={2} mr={'25vw'}>
                      </Heading>
                      <Box>
                        <Tooltip label={i18n.t('landing_page_right_panel.qr_code_tooltip')}>
                          <QRCode value={`https://${i18n.t('host')}/${folderCode}`} size={256} style={{ height: '64px', maxWidth: '100%', width: '100%' }} viewBox={'0 0 256 256'} />
                        </Tooltip>
                      </Box>
                    </View>
                  }
                </Box>
                <Box id="rightPanel" height={['43vh', '43vh', '60vh']} width={['78vw', '78vw', '25vw']} marginLeft={['4vw', '4vw', '10vw']} bg="#fff" rounded="sm" marginTop={['0vh', '0vh', '0vh']}>
                  <TouchableOpacity onPress={openDocumentPickerAsync}>
                    <Box id="uploadingBox" rounded="sm" shadow="3" bg="#fff" borderColor={draggingOverNow ? 'green.300' : 'gray.300'} borderStyle='dashed' borderWidth='4' height={['43vh', '43vh', '60vh']}>
                      {(!fileList || fileList.length === 0) && (
                        <Box mx="9" my={['5%', '5%', '35%']}>
                          <Stack rowDirection="column" height="100%" space={['2', '2', '4']} alignItems={'center'} alignContent={'center'} textAlign={'center'}>
                            <Icon as={Feather} name="plus-circle" size={['8', '8', '8']} />
                            <Heading size="sm">{i18n.t('landing_page_right_panel.headline')}
                              {i18n.t('landing_page_right_panel.headline_2')}</Heading>
                            <Box w="220" rounded="lg">
                              <TouchableOpacity onPress={openDocumentPickerAsync} style={styles.button}>
                                <Center>
                                  <Text fontWeight={'bold'} style={styles.buttonText}>{i18n.t('landing_page.click_to_action')}</Text>
                                </Center>
                              </TouchableOpacity>
                            </Box>
                          </Stack>
                        </Box>
                      )}
                      <FilesList filesList={fileList} progress={(progress)} />
                      {folderCode && (
                          <Box position='absolute' bottom='4' right='4'>
                            <HStack space={1} alignContent={'center'} alignItems={'center'}>
                              <Icon as={FontAwesome5} name="plus" />
                              <Text fontWeight={'bold'} style={styles.buttonText}>{i18n.t('landing_page.click_to_action')}</Text>
                            </HStack>
                          </Box>
                      )}
                        <React.Fragment>
                          {/*{folderCode && (*/}
                          {/*    <React.Fragment>*/}
                          {/*      <Text mt={2}>{fileList.length}</Text>*/}
                          {/*    </React.Fragment>*/}
                          {/*)}*/}
                          <Text fontWeight={folderCode ? 'bold' : 'normal'} color={folderCode ? 'text' : 'coolGray.100'}>{Math.ceil((fileList.reduce((acc, f) => (f.size || 0), 0) / (1024 * 1024)))}/150 MB</Text>
                        </React.Fragment>
                    </Box>
                  </TouchableOpacity>
                </Box>
              </Stack>
            </Container>
          </Center>
        </Box>
        <LandingPageFooter />
        <StatusBar style='auto' />
        <Box bgColor="#0551d0" width="100%" py="3">
          <Center>
            <Text color="#fff">
              <HStack space={2}>
                <Text>2013 - 2024</Text>
                <Text>info @ foldercode.com</Text>
                <Link href="https://customer.foldercode.com/" isExternal isUnderlined>{i18n.t('footer.customer_portal')}</Link>
                <Link href="https://sakirtemel.typeform.com/to/t6ibxBZ9" isExternal isUnderlined>{i18n.t('footer.feedback_form')}</Link>
                <Link href="https://foldercode.com" isExternal isUnderlined>foldercode.com</Link>
                <Link href="https://cikti.al" isExternal isUnderlined>cikti.al</Link>
              </HStack>
            </Text>
          </Center>
        </Box>
      </Stack>
    </Box>
  )
}

const styles = StyleSheet.create({
  innerContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  button: {
    // backgroundColor: '#23D3D3',
    // color: '#040e1a',
    border: '1px solid #F5B658',
    padding: 15,
    borderRadius: 5,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  buttonText: {
    // fontSize: 18,
    // color: '#fff',
    // textAlign: 'center'
  },
  thumbnail: {
    width: 300,
    height: 300,
    resizeMode: 'contain'
  },
  uploadComponent: {
    justifyContent: 'center',
    maxHeight: '450px'
  }
})
