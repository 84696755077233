import i18n from '../lib/i18n/i18n'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Link, Button, Box, HStack, VStack, Container, Center, Text, Tooltip, Icon, Badge, IconButton } from 'native-base'
import { FontAwesome5 } from '@expo/vector-icons'

import { StatusBar } from 'expo-status-bar'
import { FlatList, View, ScrollView } from 'react-native'

import { useFlags } from 'flagsmith/react'

import { FileIcon, defaultStyles } from 'react-file-icon'

import styles from './FolderFiles.style.js'

export default function FolderFiles ({ route, navigation }) {
  const flags = useFlags(['multiple_files', 'api_base_url', 'api_token', 'google_docs_viewer'])
  //   const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [isDeleting, setIsDeleting] = useState(null)

  // validate the code
  if (route.params.id.length !== 4) {
    return (
      <View style={styles.container}>
        <Text>{i18n.t('folder_files.your_code_is_wrong')}</Text>
        <StatusBar style="auto" />
      </View>
    )
  }

  // get the folder content
  useEffect(() => {
    if (!flags.api_base_url.value) {
      return
    }

    const authorizationHeader = {}
    if (flags.api_token.value !== '') {
      authorizationHeader.Authorization = 'Bearer ' + flags.api_token.value
    }

    const requestConfig = {
      headers: {
        ...authorizationHeader,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }

    axios.get((flags.api_base_url.value + 'api/v1/folder_files/' + route.params.id), requestConfig)
      .then((response) => setData(response.data))
      .catch((error) => console.error(error))
      // .finally(() => setLoading(false))
  }, [flags])

  const renderFileRow = (file) => {
    const googleDocsViewerSupportedExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pages', 'ai', 'psd', 'tiff', 'dxf', 'svg', 'eps', 'ps', 'ttf', 'xps', 'xpsx', 'zip', 'rar']
    const fileExtension = file.filename.split('.').pop()

    return (
      <Box flex={1} padding="8px" backgroundColor="#FBFBFB" mx="2px" my="10px" flexDirection='row' justifyContent='space-between' alignItems="left" textAlign="center">
        <HStack width="full" justifyContent="space-between">
        <Box width="48px" height="48px">
          <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]}/>
        </Box>
        <Box flex={1} ml="10px">
          <Box flex={1} pl="3%">
            <Link _text={{
              fontWeight: 'bold',
              _light: {
                color: 'cyan.500'
              },
              color: 'cyan.300'
            }} href={file.file_url} isUnderlined isExternal _hover={{
              _text: {
                _light: {
                  color: 'cyan.600'
                },
                color: 'cyan.400'
              }
            }}>
                {file.filename}
            </Link>
          </Box>
        </Box>
        <Box flex={['0.2', '0.2', '0.15']} justifyContent='center'>
          <HStack space={2}>
          {false && fileExtension === 'pdf' && (
            <Tooltip label="Print">
              <Link fontSize={['md', 'md', 'xl']} _text={{
                _light: {
                  color: 'cyan.500'
                },
                color: 'cyan.300'
              }} onPress={() => alert('clicked' + file.file_url)} isUnderlined={false} isExternal _hover={{
                _text: {
                  _light: {
                    color: 'cyan.600'
                  },
                  color: 'cyan.400'
                }
              }}>
                <Icon as={FontAwesome5} name="print" size={['md', 'md', 'xl']} />
              </Link>
            </Tooltip>
          )}
          <Tooltip label={i18n.t('folder_files.download_file_tooltip')}>
              <Link fontSize={['md', 'md', 'xl']} _text={{
                _light: {
                  color: 'cyan.500'
                },
                color: 'cyan.300'
              }} href={`${file.file_url}?download=true`} isUnderlined={false} isExternal _hover={{
                _text: {
                  _light: {
                    color: 'cyan.600'
                  },
                  color: 'cyan.400'
                }
              }}>
                <Icon as={FontAwesome5} name="download" size={['10', '10', '15']} />
              </Link>
            </Tooltip>
            {googleDocsViewerSupportedExtensions.includes(fileExtension) && (
              <Tooltip label={i18n.t('folder_files.open_in_google_docs_tooltip')}>
                <Link fontSize={['md', 'md', 'xl']} _text={{
                  _light: {
                    color: 'cyan.500'
                  },
                  color: 'cyan.300'
                }} href={`https://docs.google.com/viewer?url=${encodeURI(file.file_url)}`} isUnderlined={false} isExternal _hover={{
                  _text: {
                    _light: {
                      color: 'cyan.600'
                    },
                    color: 'cyan.400'
                  }
                }}>
                  <Icon as={FontAwesome5} name="google-drive" size={['10', '10', '15']} />
                </Link>
              </Tooltip>
            )}
          </HStack>
        </Box>
        </HStack>
      </Box>
    )
  }

  const handleDeleteFolderCode = () => {
    setIsDeleting(true)

    const authorizationHeader = {}
    if (flags.api_token.value !== '') {
      authorizationHeader.Authorization = 'Bearer ' + flags.api_token.value
    }

    const requestConfig = {
      headers: {
        ...authorizationHeader,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }

    axios.delete((flags.api_base_url.value + 'api/v1/folder_codes/' + route.params.id), requestConfig)
      .then((response) => {
        setData({})
        setIsDeleting(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Box flex={1} bg="#fff" alignItems="center" justifyContent="center">
        <Box width="100vw" height="107vh" flexDirection="row" bg={{
          linearGradient: {
            colors: ['#0551d0', '#01b3f9'],
            start: [0, 0.5],
            end: [0, 0.5]
            // locations: [0.5, 0.5] // TODO: light blue from both sides
          }
        }}>
        <Center marginLeft={['0vw', '0vw', '15vw']}>
          <Container>
            <VStack space={2}>
              {false && data.folderInfo && (
                <Box pt={5} mt="10" width={['85vw', '85vw', '60vw']}>
                  <VStack>
                    <Badge bg="#f5b757" rounded="full" mb={-4} ml={-1} zIndex={1} variant="solid" alignSelf="flex-start" _text={{
                      fontSize: 12,
                      fontWeight: 'extrabold'
                    }}>
                          {route.params.id}
                    </Badge>
                    <Box rounded="sm" shadow="3" bg="#fff" py="4">
                      <Text>File total size: 3 files, 120,22mb</Text>
                      <Text>File uploaded at: </Text>
                      <Text>Expiry date: </Text>
                      <Text>Download all as a zip</Text>
                      <Text>Folder visit count: 1</Text>
                      <Text>Description text</Text>
                      <Text>Info what&apos;s that about</Text>
                      <Text>Sharing / Security</Text>
                      <IconButton onPress={handleDeleteFolderCode} colorScheme="secondary" isLoading={isDeleting} isLoadingText="Deleting" isDisabled={isDeleting === false} icon={<Icon as={FontAwesome5} name="trash" size={['md', 'md', 'xl']} style={{ color: 'rgb(219, 39, 119)' }} />} />
                    </Box>
                  </VStack>
                </Box>
              )}
              {isDeleting === false && (
                <Box rounded="sm" shadow="3" bg="#fff" width={['85vw', '85vw', '60vw']}>
                  <Button colorScheme="secondary" isLoading={false} isDisabled={true}>
                    {i18n.t('folder_files.your_files_are_deleted')}
                  </Button>
                </Box>
              )}
              {!data.files
                ? ((isDeleting !== false) && (<Text>{i18n.t('folder_files.loading')}</Text>))
                : (
                  <Box id="uploadingBox" rounded="sm" shadow="3" bg="#fff" pt={3} height={['75vh', '75vh', '75vh']} width={['85vw', '85vw', '60vw']}>
                      <ScrollView showsVerticalScrollIndicator="true">
                          <FlatList
                          data={data.files}
                          keyExtractor={({ file_url }, index) => file_url} // eslint-disable-line camelcase
                          renderItem={({ item }) => renderFileRow(item)}
                        />
                      </ScrollView>
                      <Button onPress={handleDeleteFolderCode} colorScheme="secondary" isLoading={isDeleting} isLoadingText="Deleting" isDisabled={isDeleting === false}>
                        {i18n.t('folder_files.delete_my_files')}
                      </Button>
                  </Box>
                  )
              }
            </VStack>
          </Container>
        </Center>

        </Box>
      <StatusBar style="auto" />
    </Box>
  )
}
