import axios from 'axios'

export default class SequentialFileUploader {
  constructor (config) {
    this.config = config

    if (!config.apiBaseUrl) {
      throw new Error('apiBaseUrl is not defined')
    }
    const authorizationHeader = {}
    if (config.apiToken !== '') {
      authorizationHeader.Authorization = 'Bearer ' + config.apiToken
    }

    this.filesList = []
    this.uploadingNow = false
    this.progress = 0
    this.browserSession = ''
    this.folderCode = ''
    this._axiosUploaderInstance = axios.create({
      method: 'POST',
      baseURL: config.apiBaseUrl,
      url: '/upload',
      params: {},
      timeout: 0, // TODO: timeout for what?
      headers: {
        ...authorizationHeader,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this._onUploadProgress.bind(this)
    })
  }

  enqueueFilesForUpload (files) {
    [...files].forEach((file) => this.filesList.push(this._convertFileToFileHash(file)))
    this._uploadNextFile() // trigger
  }

  // _cancelCurrentUploading

  _convertFileToFileHash (file) {
    return {
      file,
      filename: file.name,
      mimeType: file.type, // get it from the extension
      status: 'not_started',
      size: file.size,
      error: '',
      uuid: Math.random().toString()
    }
  }

  async _uploadNextFile () {
    if (this.uploadingNow) {
      return
    }
    this.config.onUpdate(this)
    this.uploadingNow = true
    const fileToBeUploaded = this.filesList.find((file) => file.status === 'not_started')

    if (!fileToBeUploaded) {
      this.uploadingNow = false

      return
    }

    this.progress = 0
    fileToBeUploaded.status = 'started'

    // create an uploading session
    try {
      if (!this.browserSession) {
        const authorizationHeader = {}
        if (this.config.apiToken !== '') {
          authorizationHeader.Authorization = 'Bearer ' + this.config.apiToken
        }

        const response = await axios.post('/api/v1/uploading_sessions', {}, {
          baseURL: this.config.apiBaseUrl,
          timeout: 0, // TODO: timeout for what?
          headers: {
            ...authorizationHeader,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.browserSession = response.data.id
        this.folderCode = response.data.folderCode
      }
    } catch (error) {
      // TODO: generate a generic error
      this.uploadingNow = false
    }

    try {
      const formData = new FormData()
      formData.append('file', fileToBeUploaded.file)

      await this._axiosUploaderInstance.request({
        url: '/upload',
        data: formData,
        params: {
          browserSession: this.browserSession
        }
      })
      fileToBeUploaded.status = 'uploaded'
      this.uploadingNow = false
      this._uploadNextFile()
    } catch (error) {
      // console.log(error.response.data.interaction_diffs[0])
      fileToBeUploaded.status = 'error'
      fileToBeUploaded.error = 'Failed to upload'

      this.uploadingNow = false // on error, we still should be able to change things here
      this._uploadNextFile() // only if the error is related to the file
    }

    // TOdo: timeouts, both for uploading and both for whole flow checking
  }

  _onUploadProgress (progressEvent) {
    if (progressEvent.lengthComputable) {
      this.progress = (Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    this.config.onUpdate(this)
    // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')

    // TODO: mark the points and draw a speed chart
  }
}
