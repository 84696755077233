import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'

// import { Platform } from 'expo-modules-core'

import Constants from 'expo-constants'
import React, { useState } from 'react'
import {
  extendTheme,
  NativeBaseProvider,
  Text,
  Link,
  Box,
  HStack,
  Modal,
  FormControl,
  Input,
  Button,
  Image,
  Spacer
} from 'native-base'

import 'react-native-gesture-handler'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import i18n from './lib/i18n/i18n'

import LandingPage from './screens/LandingPage'
import FolderFiles from './screens/FolderFiles'
import CopyCenters from './screens/CopyCenters'
// import LandingPageMobile from './screens/LandingPageMobile'
import MobileQRCodeScanner from './screens/MobileQRCodeScanner'

const Stack = createNativeStackNavigator()

const linking = {
  config: {
    screens: {
      Home: '/',
      CopyCenters: '/copy-centers',
      // LandingPageMobile: '/landing-page-mobile',
      FolderFiles: '/:id'
    }
  }
}

const newColorTheme = {
  brand: {
    900: '#8287af',
    800: '#7c83db',
    700: '#b3bef6'
  }
}
const theme = extendTheme({ colors: newColorTheme })

const config = {
  dependencies: {
    'linear-gradient': require('expo-linear-gradient').LinearGradient
  }
}

export default function App (props) {
  const [showCodeModal, setShowCodeModal] = useState(false)

  // let initialRouteName = (Platform.OS === 'android' || Platform.OS === 'ios') ? 'LandingPageMobile' : 'Home'
  let initialRouteName = 'Home'
  let initialParams = {}
  let inset = {}

  if (props.initialParams && props.initialRouteName) { // TODO: remove this part after we solve the change url issue in jest
    initialRouteName = props.initialRouteName
    initialParams = props.initialParams
  }

  if (process.env.NODE_ENV === 'test') {
    inset = {
      frame: { x: 0, y: 0, width: 0, height: 0 },
      insets: { top: 0, left: 0, right: 0, bottom: 0 }
    }
  }

  const initialRef = React.useRef(null)
  const CodeModal = () => {
    return (
      <Modal size="xs" initialFocusRef={initialRef} isOpen={showCodeModal} onClose={() => setShowCodeModal(false)}>
        <Modal.Content width="5/6">
          <Modal.CloseButton />
          <Modal.Header>{i18n.t('i_have_the_code_modal.header')}</Modal.Header>
          <Modal.Body>
            <FormControl>
              <Input ref={initialRef} size="2xl" maxLength={4} />
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button onPress={() => {
                const code = initialRef.current.value
                window.location.href = '/' + code
                setShowCodeModal(false)
              }}>
                {i18n.t('i_have_the_code_modal.go_to_my_folder')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    )
  }

  const LogoTitle = () => {
    return (
      <HStack width="100%" mt={['0', '0', '4%']} ml={'80%'}>
        <Text href="/" style={{ fontSize: 18, fontWeight: '600', letterSpacing: 3, color: '#fff' }} alignItems="center" justifyContent="center">{i18n.t('nav.logo_title')}</Text>
        <Box flexDirection='row' justifyContent='right' alignItems='right' display={['flex', 'flex', 'flex']} width={['full', 'full', 'full']} position='absolute' left={'70vw'} top={0}>
            <Link _text={{
              fontWeight: '500',
              textDecoration: 'none',
              _light: {
                color: '#fff'
              },
              color: 'cyan.300'
            }} href="#i-have-the-code" _hover={{
              _text: {
                _light: {
                  color: 'cyan.600'
                },
                color: 'cyan.400'
              }
            }} onPress={() => setShowCodeModal(true)}>{i18n.t('nav.i_have_the_code')}</Link>
            <Link marginLeft='1/6' _text={{
              fontWeight: '500',
              textDecoration: 'none',
              _light: {
                color: '#fff'
              },
              color: 'cyan.300'
            }} href="/copy-centers" _hover={{
              _text: {
                _light: {
                  color: 'cyan.600'
                },
                color: 'cyan.400'
              }
            }}>{i18n.t('nav.for_copy_centers')} →</Link>
        </Box>
      </HStack>
    )
  }

  return (
    <FlagsmithProvider
      options={{
        environmentID: Constants.manifest.extra.FLAGSMITH_ENVIRONMENT_ID,
        defaultFlags: {
          api_base_url: { value: 'https://api.foldercode.com/', enabled: true } // add a default flag in case of an issue
        }
      }}
      flagsmith={flagsmith}>
      <NativeBaseProvider theme={theme} config={config} initialWindowMetrics={inset}>
        <CodeModal />
        <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
          <Stack.Navigator initialRouteName={initialRouteName} screenOptions={{ title: i18n.t('nav.default_title'), headerTransparent: true, headerTitle: (props) => <LogoTitle {...props} /> }}>
            <Stack.Screen name='Home' component={LandingPage} initialParams={initialParams} />
            <Stack.Screen name='FolderFiles' component={FolderFiles} options={{ title: i18n.t('nav.folder_files_title') }} initialParams={initialParams} />
            <Stack.Screen name='CopyCenters' component={CopyCenters} options={{ title: i18n.t('nav.copy_centers_title') }} initialParams={initialParams} />
            <Stack.Screen name='MobileQRCodeScanner' component={MobileQRCodeScanner} initialParams={initialParams} />
          </Stack.Navigator>
        </NavigationContainer>
      </NativeBaseProvider>
    </FlagsmithProvider>
  )
}
