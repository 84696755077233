import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  fileList: {
    paddingHorizontal: '25%'
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  fileRow: {
    flex: 1,
    padding: 8,
    backgroundColor: '#FBFBFB',
    marginVertical: 2,
    marginHorizontal: 10,
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'row',
    textAlignVertical: 'center'
  },

  fileRowIcon: {
    width: '48px',
    height: '48px'
    // flex: 0.2,
  },
  fileRowFilename: {
    paddingLeft: '3%',
    justifyContent: 'center',
    flex: 1
  },
  fileRowButton: {
    // flex: 0.3,
  },
  fileRowOpenInNewTabButton: {
    color: 'blue'
  }
})
