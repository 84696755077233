import i18n from '../lib/i18n/i18n'
import { Box, Text, VStack, Heading, Link, Stack } from 'native-base'
import React from 'react';

export default function LandingPageFooter () {
  return (
        <Box bg={{
          linearGradient: {
            colors: ['#0551d0', '#01b3f9'],
            start: [0, 0.5],
            end: [0, 0.5]
            // locations: [0.5, 0.5] // TODO: light blue from both sides
          }
        }} width="100%" py="0">
          <Box p={4} shadow={2} mt={4}>
            <Stack direction={['column', 'column', 'row']} space={4} justifyContent="space-between">
              <Box flex={1}>
                <VStack space={2}>
                  <Heading color="#fff">{i18n.t('landing_page_technology.header')}</Heading>
                  <Box>
                    <Text mt="2" color="#fff">{i18n.t('landing_page_technology.intro')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_technology.paragraph_1_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_technology.paragraph_1_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_technology.paragraph_2_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_technology.paragraph_2_content_part_1')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_technology.paragraph_2_content_part_2')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_technology.paragraph_3_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_technology.paragraph_3_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_technology.paragraph_4_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_technology.paragraph_4_content')}</Text>
                  </Box>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack space={2}>
                  <Heading color="#fff">{i18n.t('landing_page_security.header')}</Heading>
                  <Box>
                    <Text fontWeight="bold" color="#fff">🔐 {i18n.t('landing_page_security.paragraph_1_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_security.paragraph_1_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">🛡️ {i18n.t('landing_page_security.paragraph_2_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_security.paragraph_2_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">🖥️ {i18n.t('landing_page_security.paragraph_3_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_security.paragraph_3_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">📛 {i18n.t('landing_page_security.paragraph_4_title')}</Text>
                    <Text color="#fff"><Link href="https://cloudflare.com" isExternal isUnderlined>Cloudflare</Link> {i18n.t('landing_page_security.paragraph_4_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">⏰ {i18n.t('landing_page_security.paragraph_5_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_security.paragraph_5_content')}</Text>
                  </Box>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack space={2}>
                  <Heading color="#fff">{i18n.t('landing_page_faq.header')}</Heading>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_faq.paragraph_1_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_faq.paragraph_1_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_faq.paragraph_2_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_faq.paragraph_2_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_faq.paragraph_3_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_faq.paragraph_3_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_faq.paragraph_4_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_faq.paragraph_4_content')}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color="#fff">{i18n.t('landing_page_faq.paragraph_5_title')}</Text>
                    <Text color="#fff">{i18n.t('landing_page_faq.paragraph_5_content')}</Text>
                  </Box>
                </VStack>
              </Box>
            </Stack>
          </Box>
        </Box>
  )
}
