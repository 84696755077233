import { I18n } from 'i18n-js/dist/require'

const translations = {
  en: require('../../locales/en.json'),
  tr: require('../../locales/tr.json')
}

const i18n = new I18n(translations)

i18n.translations = translations
i18n.fallbacks = true

switch (window.location.hostname) {
  case 'foldercode.com':
    i18n.locale = 'en'
    break
  case 'cikti.al':
    i18n.locale = 'tr'
    break
  case 'beta.cikti.al':
    i18n.locale = 'tr'
    break
  case 'localhost':
    i18n.locale = 'en'
    break
  default:
    i18n.locale = 'en'
}

export default i18n
